import axios from '@/services/axios'

export default async () => {
  try {
    // return this.$router.push({ name: 'Dashboard' })
    const res = await axios.get('/api/v1/wallet')
    if(res.status == 1) return res.data
    // store.dispatch('login', res.data)
  } catch (e) {
   
  }
}
