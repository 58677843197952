<template>
	<div>

		<a-row type="flex" :gutter="24">

			<!-- Billing Info Column -->
			<a-col :span="24" :md="16">
				<a-row type="flex" :gutter="24">
					<a-col :span="24" :xl="8" class="mb-24">
						<!-- Salary Card -->
						<WidgetSalary
							:value="wallet"
							:title="$t('billing.balance')"
							:icon='icon'
						></WidgetSalary>
						<!-- / Salary Card -->
					</a-col>
					<a-col :span="24" :xl="8" class="mb-24">
						<!-- Salary Card -->
						<WidgetSalary
							:value="unpaid"
							:title="$t('billing.unpaidAmount')"
							:icon="logo"
						></WidgetSalary>
						<!-- / Salary Card -->
					</a-col>
					<a-col :span="24" :xl="8" class="mb-24">
						<!-- Salary Card -->
						<WidgetSalary
							:value="wallet < unpaid ? unpaid - wallet  : 0"
							:title="$t('billing.additionalAmount')"
							:icon='logo'
						></WidgetSalary>
						<!-- / Salary Card -->
					</a-col>
				</a-row>
				<a-row type="flex" :gutter="24">
					<a-col :span="24" class="mb-24">
						<CardBillingInfo/>
					</a-col>
				</a-row>
			</a-col>
			<!-- / Billing Info Column -->
			
			<a-col :span="24" :md="8" class="mb-24">
				<CardTransactions />
			</a-col>
		</a-row>
	</div>
</template>

<script>

	import WidgetSalary from "@/components/Widgets/WidgetSalary"
	import CardBillingInfo from "@/components/Cards/CardBillingInfo"
	import CardTransactions from "@/components/Cards/CardTransactions"
	import getWallet from "@/services/user/wallet"

	// Salary cards data
	export default ({
		components: {
			WidgetSalary,
			CardBillingInfo,
			CardTransactions,
		},
		created(){
			this.getData()
		},
		data() {
			return {
				// Salary cards data
				wallet : 0,
				unpaid : 0,
				logo : `<img src="images/logos/paypal-logo-2.png" alt="">`,
				icon : `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
											<g id="bank" transform="translate(0.75 0.75)">
												<path id="Shape" transform="translate(0.707 9.543)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
												<path id="Path" d="M10.25,0,20.5,9.19H0Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
												<path id="Path-2" data-name="Path" d="M0,.707H20.5" transform="translate(0 19.793)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
											</g>
										</svg>`,

				// Associating "Invoices" list data with its corresponding property.
				invoiceData : [],
				
				// Associating "Your Transactions" list data with its corresponding property.
				transactionsData : [],
			}
		},
		methods: {
			async getData(){
				let wallet = await getWallet()
				if(wallet){
					this.wallet = wallet.price
					this.unpaid = wallet.unpaid
				}
			}
		}
	})

</script>

<style lang="scss">
</style>